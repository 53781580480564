import { useRouter } from 'next/router';
import { useEffect } from 'react';

type Custom404Props = { url?: string };

export const Custom404 = ({ url = '/' }: Custom404Props) => {
  const router = useRouter();

  useEffect(() => {
    router.replace(url);
  });

  return null;
};
